import * as React from 'react';
import { navigate } from 'gatsby';
import { DataStore } from '@aws-amplify/datastore';
import { differenceInDays, formatISO } from 'date-fns';
import _groupBy from 'lodash/groupBy';
import { SubmitHandler, Controller } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';

import {
	Box,
	Button,
	Grid,
	Paper,
	TextField,
	FormControlLabel,
	Switch,
	Typography,
	Container,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	MenuItem,
	CircularProgress,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import ReCAPTCHA from 'react-google-recaptcha';

import GuestFields from '../components/BookingRegister/GuestFields';

import useBookingRegisterForm, {
	Inputs,
} from '../components/BookingRegister/hooks/useBookingRegisterForm';

import { Guest, Booking, GuestBooking } from '../models';

import BackgroundImage from '../images/background.jpg';
import QRCode from '../images/qrcode-pix.png';

import { formatter } from '../utils/currency';
import { putFile } from '../utils/storage';

import { bookingLocations } from '../constants/bookingLocations';

const IndexPage = () => {
	const [recaptcha, setRecaptcha] = React.useState<string | null>(null);
	const [loading, setLoading] = React.useState<boolean>(false);
	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
		control,
		setValue,
		guestsFields,
		appendGuest,
		removeGuest,
	} = useBookingRegisterForm();

	const roomSnackByCategory = React.useMemo(() => {
		if (!watch('roomSnacks')) return [];

		return _groupBy(
			watch('roomSnacks')?.map((roomSnack, index) => ({
				...roomSnack,
				index,
			})),
			'category'
		);
	}, [watch('roomSnacks')]);

	const hasPayment = React.useMemo(
		() => watch('wantParking') || watch('wantSnacks'),
		[watch('wantParking'), watch('wantSnacks')]
	);
	const bookingLocationEnabledExtras = React.useMemo(() => {
		const currentLocation = bookingLocations.find(
			l => l.value === watch('bookingLocation')
		);

		return {
			parking: currentLocation?.hasParking,
			parkingPrice: currentLocation?.parkingPrice || 20,
			snacks: currentLocation?.hasSnacks,
		};
	}, [watch('bookingLocation')]);
	const pixValue = React.useMemo(() => {
		let totalPixValue = 0;

		if (!watch('checkinDate') || !watch('checkoutDate')) {
			return formatter.format(totalPixValue);
		}

		// Parking value
		if (watch('wantParking')) {
			const bookingDays = differenceInDays(
				new Date(watch('checkoutDate')).setHours(0, 0, 0, 0),
				new Date(watch('checkinDate')).setHours(0, 0, 0, 0)
			);

			totalPixValue =
				totalPixValue + bookingLocationEnabledExtras.parkingPrice * bookingDays;
		}

		// Snacks value
		if (watch('wantSnacks')) {
			const snacksValue = watch('roomSnacks').reduce((total, roomSnack) => {
				const { quantity, price } = roomSnack || {};

				if (isNaN(quantity) || isNaN(price)) return total;

				return total + quantity * price;
			}, 0);

			totalPixValue = totalPixValue + snacksValue;
		}

		return formatter.format(totalPixValue);
	}, [
		watch('wantParking'),
		watch('checkinDate'),
		watch('checkoutDate'),
		watch('wantSnacks'),
		JSON.stringify(watch('roomSnacks')),
		bookingLocationEnabledExtras.parkingPrice,
	]);

	const onRecaptchaChange = (value: string) => {
		setRecaptcha(value);
	};

	const onSubmit: SubmitHandler<Inputs> = async data => {
		const {
			owner,
			bookingPlatform,
			bookingLocation,
			bookingCode,
			checkinDate,
			checkoutDate,
			reasonForTravel,
			wantParking = false,
			carPlate,
			carManufacturer,
			carModel,
			carColor,
			wantSnacks = false,
			roomSnacks,
			paymentVoucherFileId,
			guests,
		} = data;
		const {
			fullName,
			phoneNumber,
			email,
			birthDate,
			address,
			number,
			complement,
			neighborhood,
			city,
			state,
			cep,
			cpf,
			rg,
		} = owner.guest;

		if (!recaptcha) {
			return;
		}

		setLoading(true);

		try {
			const _documentFileIds = await Promise.all(
				owner.documentFiles.map(async documentFile => {
					const documentFileId = await putFile(documentFile, documentFile.type);

					return documentFileId;
				})
			);

			// Create owner guest
			const _owner = await DataStore.save(
				new Guest({
					fullName,
					phoneNumber,
					email,
					birthDate: birthDate && formatISO(new Date(birthDate)),
					address,
					number,
					complement,
					neighborhood,
					city,
					state,
					cep,
					documentFileId: _documentFileIds.join(','),
					cpf,
					rg,
				})
			);

			// Create booking
			const _booking = await DataStore.save(
				new Booking({
					bookingPlatform,
					bookingLocation,
					bookingCode,
					checkinDate: checkinDate && formatISO(new Date(checkinDate)),
					checkoutDate: checkoutDate && formatISO(new Date(checkoutDate)),
					reasonForTravel,
					wantParking,
					carPlate,
					carManufacturer,
					carModel,
					carColor,
					wantSnacks,
					roomSnacks,
					paymentVoucherFileId,
					ownerID: _owner.id,
					isApproved: false,
				})
			);

			// Attach owner to booking
			await DataStore.save(
				new GuestBooking({
					guest: _owner,
					booking: _booking,
				})
			);

			// Create and attach additional guests
			const _guests = await Promise.all(
				guests.map(async guestInfo => {
					const _documentFileIds = await Promise.all(
						guestInfo.documentFiles.map(async documentFile => {
							const documentFileId = await putFile(
								documentFile,
								documentFile.type
							);

							return documentFileId;
						})
					);

					const _guest = await DataStore.save(
						new Guest({
							fullName: guestInfo.guest.fullName,
							phoneNumber: guestInfo.guest.phoneNumber,
							email: guestInfo.guest.email,
							birthDate:
								guestInfo.guest.birthDate &&
								formatISO(new Date(guestInfo.guest.birthDate)),
							address: guestInfo.guest.address,
							number: guestInfo.guest.number,
							complement: guestInfo.guest.complement,
							neighborhood: guestInfo.guest.neighborhood,
							city: guestInfo.guest.city,
							state: guestInfo.guest.state,
							cep: guestInfo.guest.cep,
							documentFileId: _documentFileIds.join(','),
							cpf: guestInfo.guest.cpf,
							rg: guestInfo.guest.rg,
						})
					);

					return await DataStore.save(
						new GuestBooking({
							guest: _guest,
							booking: _booking,
						})
					);
				})
			);

			console.log(_booking, _owner, _guests);

			setLoading(false);
			navigate('/success');
		} catch (error) {
			console.log(error);

			setLoading(false);
		}
	};

	return (
		<div
			style={{
				backgroundImage: `url(${BackgroundImage})`,
				backgroundSize: 'cover',
				backgroundRepeat: 'no-repeat',
				backgroundAttachment: 'fixed',
				minHeight: 'calc(100vh - 120px)',
			}}
		>
			<Container maxWidth='md' sx={{ padding: 3 }}>
				<Paper elevation={3} sx={{ padding: 3 }}>
					<Typography
						variant='h5'
						sx={{ marginBottom: 3, textAlign: 'center' }}
						gutterBottom
					>
						Seja bem vindo ao portal de reservas Vacanza.
					</Typography>

					<Box sx={{ marginBottom: 3 }}>
						<Typography
							variant='body1'
							sx={{
								marginBottom: 2,
								paddingBottom: 1,
								borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
							}}
							gutterBottom
						>
							Dados da sua reserva
						</Typography>

						<Grid container spacing={2}>
							<Grid item xs={12} md={4}>
								<TextField
									select
									label='Plataforma da reserva*'
									variant='outlined'
									InputLabelProps={{ shrink: true }}
									error={Boolean(errors.bookingPlatform)}
									{...register('bookingPlatform', { required: true })}
									fullWidth
								>
									<MenuItem value='airbnb'>Airbnb</MenuItem>
									<MenuItem value='booking'>Booking</MenuItem>
									<MenuItem value='reserva-direta'>Reserva Direta</MenuItem>
								</TextField>
							</Grid>
							<Grid item xs={12} md={8}>
								<TextField
									label='Código da reserva*'
									variant='outlined'
									InputLabelProps={{ shrink: true }}
									error={Boolean(errors.bookingCode)}
									disabled={!Boolean(watch('bookingPlatform'))}
									{...register('bookingCode', { required: true })}
									fullWidth
								/>
							</Grid>
							<Grid item xs={12} md={4}>
								<TextField
									select
									label='Unidade*'
									variant='outlined'
									InputLabelProps={{ shrink: true }}
									error={Boolean(errors.bookingLocation)}
									{...register('bookingLocation', { required: true })}
									fullWidth
								>
									{bookingLocations.map(location => (
										<MenuItem key={location.value} value={location.value}>
											{location.name}
										</MenuItem>
									))}
								</TextField>
							</Grid>
							<Grid item xs={12} md={4}>
								<Controller
									name='checkinDate'
									control={control}
									rules={{ required: true }}
									defaultValue={''}
									render={({ field, ...props }) => {
										return (
											<DateTimePicker
												label='Data do check-in*'
												value={field.value}
												onChange={date => field.onChange(date)}
												renderInput={params => (
													<TextField
														{...params}
														variant='outlined'
														InputLabelProps={{ shrink: true }}
														error={Boolean(errors.checkinDate)}
														helperText='a partir das 15h'
														fullWidth
													/>
												)}
												inputFormat='dd/MM/yyyy HH:mm'
												minTime={new Date(0, 0, 0, 15, 0, 0)}
											/>
										);
									}}
								/>
							</Grid>
							<Grid item xs={12} md={4}>
								<Controller
									name='checkoutDate'
									control={control}
									rules={{ required: true }}
									defaultValue={''}
									render={({ field, ...props }) => {
										return (
											<DateTimePicker
												label='Data do check-out*'
												value={field.value}
												onChange={date => field.onChange(date)}
												renderInput={params => (
													<TextField
														{...params}
														variant='outlined'
														InputLabelProps={{ shrink: true }}
														error={Boolean(errors.checkoutDate)}
														helperText='até as 12h'
														fullWidth
													/>
												)}
												inputFormat='dd/MM/yyyy HH:mm'
												maxTime={new Date(0, 0, 0, 12, 0, 0)}
											/>
										);
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									label='Motivo da viagem*'
									variant='outlined'
									InputLabelProps={{ shrink: true }}
									error={Boolean(errors.reasonForTravel)}
									multiline
									rows={2}
									inputProps={{ maxLength: 200 }}
									{...register('reasonForTravel', {
										required: true,
									})}
									fullWidth
								/>
							</Grid>
						</Grid>
					</Box>

					{(bookingLocationEnabledExtras.parking ||
						bookingLocationEnabledExtras.snacks) && (
						<Box sx={{ marginBottom: 3 }}>
							<Typography
								variant='body1'
								sx={{
									marginBottom: 2,
									paddingBottom: 1,
									borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
								}}
								gutterBottom
							>
								Detalhes da sua estadia
							</Typography>

							<Grid container spacing={2}>
								{bookingLocationEnabledExtras.parking && (
									<>
										<Grid item xs={12}>
											<FormControlLabel
												control={<Switch {...register('wantParking')} />}
												label='Deseja utilizar o estacionamento?'
											/>
										</Grid>

										{/* {watch('wantParking') && (
											<>
												<Grid item xs={12}>
													<Typography variant='body1' gutterBottom>
														Para utilizar a vaga do apartamento, nós cobramos um
														valor de{' '}
														<strong>
															{formatter.format(
																bookingLocationEnabledExtras.parkingPrice
															)}
														</strong>{' '}
														à diária. O cartão é entregue pela recepção do Hotel
														no momento do check-in.
													</Typography>
													<Typography variant='body1' gutterBottom>
														O cartão de acesso ao estacionamento precisa ser
														devolvido no momento do checkout que também deve ser
														realizado na recepção do Hotel, caso não seja
														devolvido será realizada a cobrança de uma taxa de{' '}
														<strong>R$ 40,00</strong>, para emissão de um novo
														cartão.
													</Typography>
												</Grid>

												<Grid item xs={12} md={3}>
													<TextField
														label='Placa do veículo*'
														variant='outlined'
														InputLabelProps={{ shrink: true }}
														error={Boolean(errors.carPlate)}
														{...register('carPlate', {
															required: true,
														})}
														fullWidth
													/>
												</Grid>
												<Grid item xs={12} md={3}>
													<TextField
														label='Fabricante do veículo*'
														variant='outlined'
														InputLabelProps={{ shrink: true }}
														error={Boolean(errors.carManufacturer)}
														{...register('carManufacturer', {
															required: true,
														})}
														fullWidth
													/>
												</Grid>
												<Grid item xs={12} md={3}>
													<TextField
														label='Modelo do veículo*'
														variant='outlined'
														InputLabelProps={{ shrink: true }}
														error={Boolean(errors.carModel)}
														{...register('carModel', {
															required: true,
														})}
														fullWidth
													/>
												</Grid>
												<Grid item xs={12} md={3}>
													<TextField
														label='Cor do veículo*'
														variant='outlined'
														InputLabelProps={{ shrink: true }}
														error={Boolean(errors.carColor)}
														{...register('carColor', {
															required: true,
														})}
														fullWidth
													/>
												</Grid>
											</>
										)} */}
									</>
								)}

								{bookingLocationEnabledExtras.snacks && (
									<>
										<Grid item xs={12}>
											<FormControlLabel
												control={<Switch {...register('wantSnacks')} />}
												label='Deseja personalizar seu frigobar?'
											/>
										</Grid>
										{watch('wantSnacks') && (
											<>
												<Grid item xs={12}>
													<Typography variant='body1' gutterBottom>
														Temos disponível no apartamento itens de
														conveniência e frigobar.
													</Typography>
													<Typography variant='body1' gutterBottom>
														Podemos prepará-lo de maneira personalizada só para
														você ou deixar o frigobar do nosso padrão.
													</Typography>
													<Typography variant='body1' gutterBottom>
														Segue o cardápio para você personalizar como quiser:
													</Typography>
												</Grid>

												{Object.keys(roomSnackByCategory).map(categoryName => (
													<Grid item xs={12} key={categoryName}>
														<Typography variant='body1' gutterBottom>
															{categoryName}
														</Typography>

														<TableContainer
															component={Paper}
															variant='outlined'
														>
															<Table size='small'>
																<TableHead>
																	<TableRow>
																		<TableCell>Produto</TableCell>
																		<TableCell align='right'>Valor</TableCell>
																		<TableCell align='right' width={100}>
																			Quantidade
																		</TableCell>
																	</TableRow>
																</TableHead>
																<TableBody>
																	{roomSnackByCategory[categoryName].map(
																		menuItem => (
																			<TableRow
																				key={menuItem.name}
																				sx={{
																					'&:last-child td, &:last-child th': {
																						border: 0,
																					},
																				}}
																			>
																				<TableCell component='th' scope='row'>
																					{menuItem.name}
																				</TableCell>
																				<TableCell align='right'>
																					{formatter.format(menuItem.price)}
																				</TableCell>
																				<TableCell align='right'>
																					<TextField
																						variant='outlined'
																						size='small'
																						margin='dense'
																						type='number'
																						InputLabelProps={{ shrink: true }}
																						sx={{ width: 60 }}
																						{...register(
																							`roomSnacks.${menuItem.index}.quantity`,
																							{
																								valueAsNumber: true,
																							}
																						)}
																					/>
																				</TableCell>
																			</TableRow>
																		)
																	)}
																</TableBody>
															</Table>
														</TableContainer>
													</Grid>
												))}
											</>
										)}
									</>
								)}

								{/* {hasPayment && (
									<>
										<Grid
											item
											xs={12}
											sx={{
												display: 'flex',
												flexDirection: 'column',
												alignItems: 'center',
											}}
										>
											<Box
												sx={{
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
													flexWrap: 'wrap',
													gap: 2,
													border: 'solid rgba(0, 0, 0, 0.12) 1px',
													borderRadius: 2,
													padding: 3,
													marginTop: 1,
													marginBottom: 1,
												}}
											>
												<img src={QRCode} alt='' style={{ maxWidth: 200 }} />

												<Box>
													<Typography variant='body1'>Chave PIX:</Typography>
													<Typography variant='body1' fontWeight={900}>
														45.324.866/0001-96
													</Typography>
												</Box>
											</Box>

											<Typography
												variant='body1'
												sx={{ textAlign: 'center' }}
												gutterBottom
											>
												Para confirmar sua reserva, realize o pagamento do PIX,{' '}
												<br />
												no valor de {pixValue}, através do QRCode acima e <br />
												anexe o comprovante abaixo.
											</Typography>
										</Grid>
										<Grid item xs={12}>
											<Controller
												name='paymentVoucherFileId'
												control={control}
												rules={{ required: true }}
												render={({ field, ...props }) => {
													return (
														<TextField
															label='Comprovante de pagamento*'
															variant='outlined'
															type='file'
															inputProps={{ accept: '.png, .jpg, .jpeg, .pdf' }}
															InputLabelProps={{ shrink: true }}
															error={Boolean(errors.paymentVoucherFileId)}
															helperText='Faça o upload do comprovante de pagamento do PIX'
															onChange={async event => {
																field.onChange({
																	...event,
																	target: {
																		value: await putFile(
																			event.target.files[0],
																			event.target.files[0].type
																		),
																	},
																});
															}}
															InputLabelProps={{ shrink: true }}
															fullWidth
														/>
													);
												}}
											/>
										</Grid>
									</>
								)} */}
							</Grid>
						</Box>
					)}

					<Box sx={{ marginBottom: 3 }}>
						<Typography
							variant='body1'
							sx={{
								marginBottom: 2,
								paddingBottom: 1,
								borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
							}}
							gutterBottom
						>
							Dados do hóspede principal
						</Typography>

						<GuestFields
							prefix='owner'
							register={register}
							errors={errors.owner}
							control={control}
							setValue={setValue}
							onLoading={isLoading => setLoading(isLoading)}
						/>

						<Typography
							variant='body1'
							sx={{
								marginTop: 3,
								marginBottom: 2,
								paddingBottom: 1,
								borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
							}}
							gutterBottom
						>
							Dados de hóspedes adicionais
						</Typography>

						<Button variant='outlined' onClick={() => appendGuest({})}>
							Adicionar hóspede
						</Button>

						{guestsFields.map((guest, index) => (
							<>
								<Box
									sx={{
										marginTop: 3,
										marginBottom: 2,
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
									}}
								>
									<Typography variant='body1'>Hóspede {index + 2}</Typography>

									<Button variant='outlined' onClick={() => removeGuest(index)}>
										Remover hóspede
									</Button>
								</Box>

								<GuestFields
									prefix={`guests.${index}`}
									register={register}
									errors={errors.guests?.[index]}
									control={control}
									setValue={setValue}
									onLoading={isLoading => setLoading(isLoading)}
								/>
							</>
						))}
					</Box>

					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
						}}
					>
						<ReCAPTCHA
							sitekey='6LeDCRkhAAAAAGTfe2fbdFbDCBXC2uzIIlLJ7vWb'
							onChange={onRecaptchaChange}
						/>

						<Box sx={{ color: '#fff' }}>
							<Button
								variant='contained'
								onClick={handleSubmit(onSubmit)}
								sx={{ marginTop: 3 }}
								endIcon={
									loading && <CircularProgress size={16} color='inherit' />
								}
								disabled={loading}
							>
								Enviar
							</Button>
						</Box>
					</Box>
				</Paper>
			</Container>

			<DevTool control={control} />
		</div>
	);
};

export default IndexPage;
